<template>
  <div class="keenDown">
    <div v-loading="loading"></div>
    <div class="crumb" v-if="clientWidth > 750">
      <breadcrumb :refer="refer"></breadcrumb>
    </div>
    <h1 class="keenDown-title">{{ keenDowns.activityName }}</h1>
    <div class="keenDown-content-after">
      <div class="keenDown-content-after-box">
        <div class="keenDown-activity flex0">
          <img src="@/assets/PC/keenMind/keen-msg.png" alt="">
          <b class="violet ml">活动信息</b>
        </div>
        <div class="keenDown-activity-d tc">
          <div class="keenDown-activity-d-date co-999 inline">活动日期：<b> {{ keenDowns.activityDate }} </b></div><br>
          <!-- <div class="keenDown-activity-d-time co-999 inline">活动时间：<b> {{ keenDowns.activityTime }} </b></div><br> -->
          <div class="keenDown-activity-d-lecturer co-999 inline">讲师：<b> {{ keenDowns.lecturer }} </b></div>
        </div>
      </div>
    </div>
    
    <div v-if="keenDowns.lightspot" class="keenDown-content-after">
      <div class="keenDown-content-after-box">
        <div class="keenDown-activity flex0">
          <img src="@/assets/PC/keenMind/keen-avt.png" alt="">
          <b class="violet ml">活动亮点</b>
        </div>
        <div class="keenDown-activity-d keenDown-activity-lightspot tc co-999">
          {{ keenDowns.lightspot }}
        </div>
      </div>
    </div>


    <div v-if="keenDowns.lecturerInfo" class="keenDown-content-after">
      <div class="keenDown-content-after-box">
        <div class="keenDown-activity flex0">
          <img src="@/assets/PC/keenMind/keen-pro.png" alt="">
          <b class="violet ml">讲师介绍</b>
        </div>
        <div class="keenDown-activity-d keenDown-activity-lecturerInfo tc co-999">
          {{ keenDowns.lecturerInfo }}
        </div>
      </div>
    </div>
    <div v-if="keenDowns.pdfFileUrl" class="keenDown-pdfs">
      <div class="keenDown-pdf-item flex2">
        <img src="@/assets/PC/fenceDetail/pdf-icon.png" />
        <span class="pdf-name ml hidden">{{ keenDowns.pdfFileName }}</span>
        <div style="white-space: nowrap">
          <span class="link ml" @click="onlinePdf">在线观看</span>
          <!-- | <span class="link" @click="downPdf">下载阅读</span> -->
        </div>
      </div>
    </div>
    <div v-if="keenDowns.videoUrl" class="keenDown-videos tc">
      <div class="keenDown-videos-title co-fff">回放</div>
      <videos :videoUrl="keenDowns.videoUrl"></videos>
    </div>
    <div class="description" v-html="keenDowns.context"></div>
  </div>
</template>

<script>
import breadcrumb from "@/components/Breadcrumb/index.vue";
import videos from "@/components/Xgvideos/Index.vue"
import { GetActivityPlayback } from '@/api/KeenMind.js';
import { dataState } from "@/api/all.js";

export default {
  name: "keenDown",
  data() {
    return {
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣", href: "/Home/keenMind" },
      ],
      loading: true,                          // !loading
      keenDowns: {},                          // !页面详情
    }
  },
  mounted() {
    if(!this.$route.query.id){
      this.$toast('页面异常，正在返回首页');
      setTimeout(() => {
        this.$router.push('/Home/keenMind');
      }, 500);
      return false;
    }
    this.GetInit();

    // 数据统计
    const data = {
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "keenDown",
      "relationId": this.$route.query.id,
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    });
  },
  methods: {
    // !初始化列表
    GetInit() {
      const Id = { id: this.$route.query.id }
      GetActivityPlayback(Id).then(res => {
        if(res.status == 1) {
          this.keenDowns = res.data;
          if(this.refer.length < 3){
            this.refer.push({'name': res.data.activityName})
          }
        } else {
          this.$toast(res.message);
          this.$router.push('/Home/keenMind');
        }
        this.loading = false;
      })
    },
    // !预览PDF
    onlinePdf() {
      let u = navigator.userAgent, 
      isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (document.documentElement.clientWidth > 750) {
        window.open(this.keenDowns.pdfFileUrl);
      } else {
        window.location.href = this.keenDowns.pdfFileUrl;
      }
    },
    // !下载PDF
    downPdf() {
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth <= 750 && !isIOS) {
        window.location.href = this.keenDowns.pdfFileUrl;
      } else if(clientWidth > 750){
        let url = this.keenDowns.pdfFileUrl;
        let name = this.keenDowns.pdfFileName;
        // 发送http请求，将文件链接转换成文件流
        fileAjax(
          url,
          function (xhr) {
            downloadFile(xhr.response, name);
          },
          {
            responseType: "blob",
          }
        );
      }else if (isIOS) {
        window.location.href = this.keenDowns.pdfFileUrl;
        // Fence.getDownloadAPI(this.detailList.pdfFileUrl).then(res => {
        //   if(res.size > 0){
        //     const content = res
        //     const blob = new Blob([content])
        //     const fileName = this.detailList.pdfFileName
        //     navigator.msSaveBlob(blob, fileName)
        //   }
        //     /* this.$message.success("导出成功")*/
        // })
      }
      function fileAjax(url, callback, options) {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        if (options.responseType) {
          xhr.responseType = options.responseType;
        }
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            callback(xhr);
          }
        };
        xhr.send();
      }

      function downloadFile(content, filename) {
        window.URL = window.URL || window.webkitURL;
        let a = document.createElement("a");
        let blob = new Blob([content]);
        // 通过二进制文件创建url
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        // 销毁创建的url
        window.URL.revokeObjectURL(url);
      }
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
  },
  components: {
    breadcrumb,
    videos,
  },
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .keenDown {
    max-width: 1200px; margin: 0 auto;
    .keenDown-title {font-size: 20px; margin-bottom: 12px;}

    .keenDown-content-after {margin-top: 40px; border: 1px #EBEBEB solid; padding-bottom: 15px; text-align: center;}
    .keenDown-content-after-box {margin-top: -10px;}
    .keenDown-activity { width: 150px; padding-bottom: 6px; margin: 0 auto; background: #fff; margin-bottom: 15px;}
    .keenDown-activity img {width: 22px; height: 20px;}
    .keenDown-activity b {font-size: 20px; margin-top: -5px;}

    .keenDown-activity-d {width: 800px; margin: 0 auto; line-height: 2;}
    .keenDown-activity-d div, .keenDown-activity-d b {font-size: 16px;} 

    .keenDown-activity-lightspot,.keenDown-activity-lecturerInfo {font-size: 16px; line-height: 24px;}
    .keenDown-videos {position: relative;}
    .keenDown-videos-title {padding: 0 15px;position: absolute; left: 0; top: 0; line-height: 30px; background: violet; border-radius: 0 0 15px 0; z-index: 1000;}
    .keenDown-videos, .keenDown-pdfs {max-width: 100%; margin-top: 40px;}
    .description {padding-top: 20px; padding-bottom: 70px;}
  }

  @media (max-width: 750px) {
    .keenDown {
      padding: vw(40);
      .keenDown-content-after {margin-top: vw(50); padding-bottom: 0;}
      
      .keenDown-content-after-box {padding: 0 vw(20) vw(20) vw(20);}

      .keenDown-title {font-size: vw(36);}
      .keenDown-pdf-item {flex-wrap: wrap;}
      .keenDown-activity {width: auto; display: inline-block; padding: 0 vw(17) vw(17) vw(17); margin: 0 auto; }
      .keenDown-activity img {width: vw(31); height: vw(28);}
      .keenDown-activity b {font-size: vw(28); margin-top: 0;}
      .keenDown-activity-d {width: 100%; display: block;}
      .keenDown-activity-d div, .keenDown-activity-d b {font-size: vw(24);} 

      .pdf-name{width: calc(100% - 33px); display: inline-block};
      .keenDown-activity-lightspot,.keenDown-activity-lecturerInfo {font-size: vw(24); line-height: vw(36); text-align: left;}
      .keenDown-videos, .keenDown-pdfs {margin-top: vw(40);}
      .description {padding-top: vw(30); padding-bottom: 0;}
    }
  }
</style>